import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";
import { GET_ENTRUST_STATICS, GET_ENTRUST_USER_LIST } from "../store/types";

export default {
  methods: {
    getEntrustUserList(params = {page: 1}){
      return new Promise(((resolve, reject) => {
        this.$store.dispatch(GET_ENTRUST_USER_LIST, params)
        .then(resolve).catch(reject)
      }))
    },
    getEntrustStatics(params = {}){
      return new Promise(((resolve, reject) => {
        this.$store.dispatch(GET_ENTRUST_STATICS, params)
        .then(resolve).catch(reject)
      }))
    }
  }
}
