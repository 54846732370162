<template>
  <div class="user-list">
    <error :errors="userError" />
    <b-overlay :show="formBusy">
      <portlet title="كىلىشىمدىكى ئەزالار" >
        <template v-slot:toolbar>
          <b-row class="align-items-center d-none d-sm-flex">
            <b-col md="5" sm="5" >
              <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col md="5" sm="5" >
              <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col md="2" sm="2" >
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                يېڭىلاش
              </button>
            </b-col>
          </b-row>
        </template>
        <template v-slot:body>
<!--          ئۈستىدىكى سىتاستىكا باشلاندى-->
          <div class="row">
            <div class="col">
              <!--Begin::Section-->
              <div class="row row-no-padding row-col-separator-xl">
                <div class="col-md-12 col-lg-12 col-xl-4">
                  <!--begin:: Widgets/Stats2-1 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">كېلىشىمدىكى ئەزالار</h3>
                        <span class="kt-widget1__desc">بارلىق كېلىشىم ئەزالىرى</span>
                      </div>
                      <span class="kt-widget1__number kt-font-brand">{{ entrustStatics.all_order_count }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">بىكار قىلغان ئەزالار</h3>
                        <span class="kt-widget1__desc">بىكار قىلغانلار سانى</span>
                      </div>
                      <span class="kt-widget1__number kt-font-danger">{{ entrustStatics.all_quite_count }}</span>
                    </div>
                    <!--<div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">تۇتۇلغان پۇل</h3>
                        <span class="kt-widget1__desc">بارلىق تۇتۇلغان پۇل</span>
                      </div>
                      <span class="kt-widget1__number kt-font-success">125000</span>
                    </div>-->
                  </div>
                  <!--end:: Widgets/Stats2-1 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">

                  <!--begin:: Widgets/Stats2-2 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">پۇل تۇتۇلغان ئەزالار</h3>
                        <span class="kt-widget1__desc">ئاپتۇماتىك پۇل تۇتۇلغانلار</span>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ entrustStatics.all_success_order_count }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">پۇل تۇتۇشنى ساقلاۋاتقانلار</h3>
                        <span class="kt-widget1__desc">پۇل تۇتۇش مۇددىتى توشمىغانلار</span>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ entrustStatics.all_pending_order_count }}</span>
                    </div>
                    <!--<div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">تۇتۇلغان پۇل</h3>
                        <span class="kt-widget1__desc">پۇل تۇتۇلغان ئەزالاردىن تۇتۇلغان پۇل</span>
                      </div>
                      <span class="kt-widget1__number kt-font-warning">12000</span>
                    </div>-->
                  </div>

                  <!--end:: Widgets/Stats2-2 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">

                  <!--begin:: Widgets/Stats2-3 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">تۇتۇلغان پۇل</h3>
                        <span class="kt-widget1__desc">ئاپتوماتىك تۇتۇلغان پۇل</span>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ entrustStatics.all_success_order_total_price }} ￥</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title"> ساقلاۋاتقان پۇل</h3>
                        <span class="kt-widget1__desc">ئاپتوماتىك تۇتۇشنى ساقلاۋاتقان پۇل</span>
                      </div>
                      <span class="kt-widget1__number kt-font-danger">{{ entrustStatics.all_pending_order_total_price }} ￥</span>
                    </div>
                    <!--<div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">Revenue</h3>
                        <span class="kt-widget1__desc">Overall Revenue Increase</span>
                      </div>
                      <span class="kt-widget1__number kt-font-primary">+60%</span>
                    </div>-->
                  </div>

                  <!--end:: Widgets/Stats2-3 -->
                </div>
              </div>
              <!--End::Section-->
            </div>
          </div>
          <b-row class="m-t-2">
            <b-col>
              <b-table :show-empty="true" id="userList" :busy="tableBusy" :fields="tableFields" :items="entrustUsers">
                <template v-slot:empty="scope">
                  <p class="text-center"> ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن . </p>
                </template>
                <template v-slot:cell(user.avatar)="row">
                  <b-img-lazy class="fa loading" height="40" :src="row.item.user.avatar || '/assets/media/users/default.jpg'" blank-src="/assets/media/misc/loading.svg" />
                </template>
                <template v-slot:cell(user.nickname)="row">
                  {{ row.item.user.nickname }}
                </template>
              </b-table>
              <b-pagination
                v-if="entrustUsersMeta.total"
                class="my-2"
                v-model="entrustUsersMeta.current_page"
                :total-rows="entrustUsersMeta.total"
                :per-page="entrustUsersMeta.per_page"
                aria-controls="userList"
                @change="onChangePage"
              />
            </b-col>
          </b-row>
<!--          ئۈستىدىكى سىتاستىكا ئاياقلاشتى-->
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../../partials/content/Portlet";
  import Error from "../../../../partials/widgets/Error";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  import entrustUserMixin from "../../../../../mixins/entrustUserMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    components: { Error, Portlet, Datetime },
    mixins: [ entrustUserMixin, formBusyMixin ],
    computed: {
      ...mapGetters(['userError', 'entrustUsers', 'entrustUsersMeta', 'entrustStatics'])
    },
    created() {
      this.getEntrustStatics();
      this.getEntrustUserList();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "list" },
        { title: "كىلىشىم ئەزالار" },
        { title: "بارلىق ئەزالار" }
      ]);
    },
    data(){
      return {
        formBusy: false,
        formData: {
          start_time: '',
          end_time: '',
          page: 1
        },
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "user.avatar", label: "باش سۆرىتى", class: ['d-none', 'd-md-table-cell'] },
          { key: "user.nickname", label: "تور نامى" },
          { key: "next_time_ago", label: "پۇل كولىنىش ۋاقتى" },
          { key: "entrust_order_count", label: "كولانغان قېتىم سانى", class: ['d-none', 'd-md-table-cell'], formatter: (value)=>{
            return value > 0 ? `${value} قېتىم ` : 'يېڭى ئەزا'
            }},
        ],
        tableBusy: false
      };
    },
    methods: {
      refreshList(){
        this.showBusy()
        this.formData.page = 1;
        Promise.all([
          this.getEntrustStatics(this.formData),
          this.getEntrustUserList(this.formData)
        ])
        .then(()=>{ this.hideBusy() })
        .catch(()=>{ this.hideBusy() })
      },
      setTableBusy(tableBusy = false){
        this.tableBusy = tableBusy;
      },
      onChangePage(page = 1){
        this.setTableBusy(true);
        this.formData.page = page;
        this.getEntrustUserList(this.formData)
          .then(()=>{ this.setTableBusy(false)})
          .catch(()=>{ this.setTableBusy(false)})
      }
    }
  }
</script>
<style scoped>

</style>
